// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "./colors.css";

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

h2 {
  font-weight: 500;
  font-size: 36px;
  color: var(--color-gray);
}

h3 {
  color: #444;
  font-size: 24px;
  margin-bottom: 20px;
}

h4 {
  font-size: 20px;
  margin-bottom: 8px;
  color: var(--color-gray);
}

//animation

@keyframes slide-in {
  0% {
    transform: translateX(300px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(300px);
  }
}

.slide-in {
  animation: slide-in 500ms ease;
}

.slide-out {
  animation: slide-out 500ms ease;
}

// muliple section styles

.about,
.services,
.services-picker,
.my-plans,
.why,
.contact-us,
.plan-data,
footer {
  padding: 50px 20px;
  @media (min-width: 900px) {
    padding: 100px 20px;
  }
}

//Modal

.modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 4;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .modal {
    text-align: center;
    padding: 50px;
    border-radius: 8px;
    background-color: #fff;

    img {
      width: 200px;
      height: auto;
      margin-bottom: 16px;
    }

    p:nth-child(2) {
      font-size: 24px;
      margin-bottom: 8px;
    }

    .btn {
      margin-top: 24px;
      &:hover {
        color: var(--color-blue);
        border-color: var(--color-blue);
      }
    }
  }
}

//Login-Modal

.login-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 4;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  input {
    display: block;
  }

  input,
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid hsl(210, 14%, 83%);

    &:focus {
      outline: none;
    }
  }

  input {
    margin-bottom: 16px;
  }

  .modal {
    text-align: center;
    padding: 50px;
    border-radius: 8px;
    background-color: #fff;

    img {
      width: 200px;
      height: auto;
      margin-bottom: 16px;
    }

    p:nth-child(2) {
      font-size: 24px;
      margin-bottom: 8px;
    }

    .btn {
      margin-top: 24px;
      &:hover {
        background-color: transparent;
        border-color: var(--color-theme);
        color: var(--color-theme);
      }
    }

    .btn-login-forgot {
      display: block;
      background-color: transparent;
      border-color: transparent;
    }

    .btn-login-light {
      background-color: transparent;
      border-color: var(--color-theme);
      color: var(--color-theme);

      &:hover {
        color: #fff;
        background-color: var(--color-theme);
        border-color: transparent;
      }
    }
  }
}

//mobile nav

.mobile-bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 4;
  right: 0;

  i {
    position: absolute;
    color: #fff;
    left: 20px;
    top: 20px;
    font-size: 24px;
  }

  ul {
    position: absolute;
    padding: 20px;
    width: 300px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;

    a {
      color: #fff;
      font-weight: 500;
    }

    li {
      padding: 10px 0;
    }
  }
}

//mobile nav

.mobile-bg2 {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 4;
  right: 0;

  i {
    position: absolute;
    color: #fff;
    left: 20px;
    top: 20px;
    font-size: 24px;
  }

  ul {
    position: absolute;
    padding: 20px;
    width: 300px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;

    a {
      color: #fff;
      font-weight: 500;
    }

    li {
      padding: 10px 0;
    }
  }
}

// nav

header {
  // height: 79px;
  padding-bottom: 20px;
  position: fixed;
  width: 100%;
  z-index: 3;
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.3);

  nav {
    display: flex;
    align-items: center;
    padding-top: 17.5px;
    padding-left: 10px;

    img {
      top: 20.5px;
      height: 40px;
    }

    i {
      z-index: 6;
      font-size: 24px;
      flex: 1;
      text-align: right;
      @media (min-width: 1200px) {
        display: none !important;
      }
    }

    ul {
      flex: 1;
      justify-content: flex-end;
      display: none;
      @media (min-width: 1200px) {
        display: flex;
      }

      a {
        color: var(--color-theme);
        font-size: 14px;
        font-weight: 500;
        padding: 0 15px;
        margin-left: 15px;
      }
    }
  }
}

.hero {
  padding: 150px 0;
  background: url("/img/intro-bg.png") center bottom no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;

  .flex-row {
    padding: 0 32px;
    gap: 32px;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1000px) {
      flex-direction: row;
    }
  }

  h2 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 32px;
    text-align: center;
    @media (min-width: 1000px) {
      text-align: initial;
      font-size: 48px;
    }
  }

  .left {
    flex: 1;
    order: 2;
    justify-content: center;
    align-items: center;
    @media (min-width: 1000px) {
      order: 1;
    }
  }

  .buttonSpecial {
    @media (min-width: 1000px) {
      margin-left: 20px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1000px) {
      flex-direction: row;
    }

    .btn {
      text-align: center;
      width: 200px;
      margin-bottom: 16px;
      @media (min-width: 1000px) {
        margin-right: 16px;
      }
    }
  }

  img {
    display: flex;
    flex: 1;
    order: 1;
    width: 50%;
    @media (min-width: 1000px) {
      order: 2;
    }
  }
}

//about us

.about {
  img {
    width: 60%;
    @media (min-width: 900px) {
      width: 100%;
    }
  }

  h2 {
    text-align: center;
  }

  p {
    line-height: 1.7;
  }

  .flex-row {
    gap: 50px;
    flex-direction: column;
    @media (min-width: 900px) {
      flex-direction: row;
    }
  }

  .left,
  .right {
    flex: 1;
  }

  .left {
    .flex-row {
      gap: 0;
      flex-direction: column;
      margin-top: 32px;
      align-items: center;
      @media (min-width: 500px) {
        flex-direction: row;
      }
    }

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      @media (min-width: 500px) {
        margin-top: 0;
        align-items: initial;
      }

      p {
        color: #444;
        font-size: 14px;
      }
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      border: 2px solid var(--color-light-gray);
      border-radius: 50%;
      transition: 500ms ease all;
      color: var(--color-theme);
      font-size: 25px;
      @media (min-width: 500px) {
        margin-right: 16px;
      }

      &:hover {
        background-color: var(--color-theme);
        border-color: transparent;
        color: #fff;
      }
    }
  }
}

.about-1 {
  .left {
    text-align: center;
    @media (min-width: 500px) {
      text-align: initial;
    }
  }
}

.about-1,
.about-3 {
  .right {
    display: flex;
    justify-content: center;
    @media (min-width: 900px) {
      align-items: flex-start;
    }
  }
}

.about-2 {
  .left {
    order: 2;
    display: flex;
    justify-content: center;
    @media (min-width: 900px) {
      order: 1;
      align-items: flex-start;
    }
  }

  .right {
    order: 1;
    @media (min-width: 900px) {
      order: 2;
    }
  }
}

.about-2,
.about-3 {
  p {
    margin-bottom: 20px;
  }
}

//services

.services {
  background-color: #191919;
  .container {
    max-width: 1200px;
  }

  h2 {
    text-align: center;
  }

  .grid {
    max-width: 600px;
    margin: 0 auto;
    grid-template-columns: 1fr;
    gap: 32px;
    row-gap: 50px;
    @media (min-width: 800px) {
      grid-template-columns: repeat(3, 1fr);
      max-width: 100%;
    }
  }

  .flex-row {
    cursor: pointer;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    padding: 30px;
    background-color: #fff;
    transition: 500ms ease all;

    &:hover {
      transform: translateY(-4px);
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
      line-height: 1.7;
    }

    i {
      font-size: 45px;
      position: absolute;
      left: 20px;
      display: flex;
      align-self: center;
    }

    .right {
      margin-left: 60px;
    }
  }
}

//services-picker

.services-picker {
  background-color: #ecf5ff;

  .container {
    max-width: 1200px;
  }

  h2 {
    text-align: center;
  }

  .grid {
    max-width: 600px;
    margin: 0 auto;
    grid-template-columns: 1fr;
    gap: 32px;
    row-gap: 50px;
    @media (min-width: 1250px) {
      grid-template-columns: repeat(3, 1fr);
      max-width: 100%;
    }
  }

  .flex-row {
    cursor: pointer;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    padding: 30px;
    background-color: #fff;
    transition: 500ms ease all;
    color: #000;

    &:hover {
      transform: translateY(-4px);
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
      line-height: 1.7;
    }

    i {
      font-size: 45px;
      position: absolute;
      left: 20px;
      display: flex;
      align-self: center;
    }

    .right {
      margin-left: 60px;
    }
  }

  .level-selected {
    background-color: #21252b; //#27559a87;
    color: #ffffff;
    h3 {
      color: #fff;
    }
  }
}

//my-plans

.my-plans {
  background-color: #ecf5ff;

  .container {
    max-width: 1200px;
  }

  h2 {
    text-align: center;
  }

  .grid {
    max-width: 600px;
    // margin: 0 auto;
    // grid-template-cozlumns: 1fr;
    gap: 32px;
    row-gap: 50px;
    @media (min-width: 1250px) {
      grid-template-columns: repeat(3, 1fr);
      max-width: 100%;
    }
  }

  .flex-row {
    cursor: pointer;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    padding: 30px;
    background-color: #fff;
    transition: 500ms ease all;

    &:hover {
      transform: translateY(-4px);
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
      line-height: 1.7;
    }

    i {
      font-size: 45px;
      position: absolute;
      left: 20px;
      display: flex;
      align-self: center;
    }

    .right {
      margin-left: 60px;
    }
  }
}

//why

.why {
  background-color: #004899;
  color: #fff;

  h2,
  h3 {
    color: #fff;
    text-align: center;
  }

  .flex-row {
    margin-top: 60px;
    gap: 60px;
  }

  .write-up {
    margin: 0 auto;
    max-width: 600px;
    flex-direction: column;
    @media (min-width: 900px) {
      max-width: 100%;
      flex-direction: row;
    }

    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      cursor: pointer;
      padding: 40px;
      border-radius: 12px;
      background-color: #00458f;
      text-align: center;
      transition: 500ms ease all;

      &:hover {
        background-color: #003b7a;

        span {
          border-bottom: 2px solid #004a99;
        }
      }
    }

    span {
      max-width: 100%;
      margin-top: auto;
      padding-bottom: 4px;
      border-bottom: 2px solid transparent;
    }

    i {
      font-size: 50px;
    }

    h3 {
      margin: 16px 0;
    }

    p {
      font-size: 14px;
      margin-bottom: 32px;
    }
  }

  .analytics {
    flex-direction: row;
    margin-top: 90px;
    justify-content: space-evenly;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: row;
    }

    span {
      display: block;
      text-align: center;
    }

    .number {
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .title {
      font-size: 14px;
    }
  }
}

//contact us

.contact-us {
  h2,
  p {
    text-align: center;
    margin-bottom: 60px;
  }

  input,
  button {
    display: block;
  }

  input,
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;

    &:focus {
      outline: none;
    }
  }

  input {
    margin-bottom: 16px;
  }
  textarea {
    height: 100%;
    resize: none;
  }

  form {
    max-width: 700px;
    margin: 0 auto;
  }

  .flex-row {
    flex-direction: column;
    @media (min-width: 500px) {
      flex-direction: row;
      gap: 32px;
    }
  }

  .btn {
    margin-top: 16px;
    font-weight: 400;
    &:hover {
      color: var(--color-blue);
      border-color: var(--color-blue);
    }
  }
}

//plan data

.plan-data {
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  p {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  input,
  button {
    display: block;
  }

  input,
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;

    &:focus {
      outline: none;
    }
  }

  input {
    margin-bottom: 16px;
  }
  textarea {
    resize: none;
    overflow: hidden;
    min-height: 60px;
    max-height: 150px;
  }

  form {
    max-width: 700px;
    margin: 0 auto;
  }

  .flex-row {
    flex-direction: column;
    @media (min-width: 500px) {
      flex-direction: row;
      gap: 32px;
    }
  }

  .btn {
    margin-top: 16px;
    font-weight: 400;
    &:hover {
      color: var(--color-blue);
      border-color: var(--color-blue);
    }
  }
}

//footer

footer {
  color: #fff;
  background-color: var(--color-light-gray);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ul {
    margin-bottom: 32px;
    flex-wrap: wrap;
    justify-content: center;
  }

  li {
    padding: 0 15px;
    margin-bottom: 16px;
  }

  a {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }

  i {
    cursor: pointer;
    font-size: 30px;
  }

  p {
    text-align: center;
  }
}

.menu-sidebar {
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 78px;
    background: var(--color-light-gray);
    padding: 6px 14px;
    z-index: 99;
    transition: all 0.5s ease;
    border-right: 0.5px solid white;
  }
  .sidebar.open {
    width: 250px;
  }
  .sidebar .logo-details {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .sidebar .logo-details .icon {
    opacity: 0;
    transition: all 0.5s ease;
  }
  .sidebar .logo-details .logo_name {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .sidebar.open .logo-details .icon,
  .sidebar.open .logo-details .logo_name {
    opacity: 1;
  }
  .sidebar .logo-details #btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 22px;
    transition: all 0.4s ease;
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .sidebar.open .logo-details #btn {
    text-align: right;
  }
  .sidebar i {
    color: #fff;
    height: 60px;
    min-width: 50px;
    font-size: 28px;
    text-align: center;
    line-height: 60px;
  }
  .sidebar .nav-list {
    margin-top: 20px;
    height: 100%;
  }
  .sidebar li {
    position: relative;
    margin: 8px 0;
    list-style: none;
  }
  .sidebar li .tooltip {
    position: absolute;
    top: -20px;
    left: calc(100% + 15px);
    z-index: 3;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: 0s;
  }
  .sidebar li:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
  }
  .sidebar.open li .tooltip {
    display: none;
  }
  .sidebar input {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    outline: none;
    height: 50px;
    width: 100%;
    width: 50px;
    border: none;
    border-radius: 12px;
    transition: all 0.5s ease;
    background: var(--color-dark-gray);
  }
  .sidebar.open input {
    padding: 0 20px 0 50px;
    width: 100%;
  }
  .sidebar .bx-search {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 22px;
    background: var(--color-dark-gray);
    color: #fff;
  }
  .sidebar.open .bx-search:hover {
    background: var(--color-dark-gray);
    color: #fff;
  }
  .sidebar .bx-search:hover {
    background: #fff;
    color: var(--color-light-gray);
  }
  .sidebar li a {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
    background: var(--color-light-gray);
  }
  .sidebar li a:hover {
    background: #fff;
  }
  .sidebar li a .links_name {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
  }
  .sidebar.open li a .links_name {
    opacity: 1;
    pointer-events: auto;
  }
  .sidebar li a:hover .links_name,
  .sidebar li a:hover i {
    transition: all 0.5s ease;
    color: var(--color-light-gray);
  }
  .sidebar li i {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    border-radius: 12px;
  }
  .sidebar li.profile {
    position: fixed;
    height: 60px;
    width: 78px;
    left: 0;
    bottom: -8px;
    padding: 10px 14px;
    background: var(--color-light-gray);
    transition: all 0.5s ease;
    overflow: hidden;
    border-right: 0.5px solid white;
  }
  .sidebar.open li.profile {
    width: 250px;
    list-style-position: inside;
    border-right: 0.5px solid white;
  }
  .sidebar li .profile-details {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .sidebar li img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 10px;
  }
  .sidebar li.profile .name,
  .sidebar li.profile .job {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    white-space: nowrap;
  }
  .sidebar li.profile .job {
    font-size: 12px;
  }
  .sidebar .profile #log_out {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: var(--color-dark-gray);
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-radius: 0px;
    transition: all 0.5s ease;
  }
  .sidebar.open .profile #log_out {
    width: 50px;
    background: none;
  }

  .foot {
    position: relative;
    left: 78px;
    width: calc(100% - 78px);
    transition: all 0.5s ease;
    z-index: 2;
  }

  .sidebar.open ~ .foot {
    position: relative;
    left: 250px;
    width: calc(100% - 250px);
  }

  .home-section {
    position: relative;
    background: #ffffff;
    min-height: 100vh;
    top: 0;
    left: 78px;
    width: calc(100% - 78px);
    transition: all 0.5s ease;
    z-index: 2;
  }
  .sidebar.open ~ .home-section {
    left: 250px;
    width: calc(100% - 250px);
  }
  .home-section .text {
    display: inline-block;
    color: var(--color-dark-gray);
    font-size: 25px;
    font-weight: 500;
    margin: 18px;
  }
  @media (max-width: 420px) {
    .sidebar li .tooltip {
      display: none;
    }
  }
}

//utility classes
.container {
  // max-width: 1140px;
  max-width: 1250px;
  margin: 0 auto;
}

.display-none {
  display: none;
}

.flex-row {
  display: flex;
}

.grid {
  display: grid;
}

.btn {
  cursor: pointer;
  display: inline-block;
  background-color: var(--color-theme);
  padding: 14px 40px;
  color: #fff;
  border-radius: 45px;
  border: 2px solid transparent;
  font-weight: 600;
  transition: 500ms ease all;

  &:hover {
    background-color: transparent;
    border-color: #fff;
  }
}

.btn-light {
  background-color: transparent;
  border-color: var(--color-theme);
  color: var(--color-theme);

  &:hover {
    color: black;
    background-color: var(--color-theme);
    border-color: transparent;
  }
}

.btn-gray {
  background-color: var(--color-light-gray);
  border-color: var(--color-light-gray);
  color: white;

  &:hover {
    // background-color: transparent;
    border-color: var(--color-light-gray) !important;
    color: var(--color-light-gray) !important;
  }
}

.btn-red {
  background-color: transparent;
  border-color: red;
  color: red;

  &:hover {
    background-color: red;
    border-color: red !important;
    color: white !important;
  }
}

.form-button {
  max-width: 700px;
  margin: 0 auto;
}

.grid-workouts {
  display: grid;
  max-width: 450px;
  margin: 0 auto;
  grid-template-columns: 1fr;
  gap: 32px;
  row-gap: 50px;
  @media (min-width: 800px) {
    grid-template-columns: repeat(2, 1fr) !important;
    max-width: 100%;
  }
  @media (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr) !important;
    max-width: 100%;
  }
  @media (min-width: 1250px) {
    grid-template-columns: repeat(4, 1fr) !important;
    max-width: 100%;
  }
}

.grid-sessions {
  display: grid;
  max-width: 300px;
  margin: 0 auto;
  grid-template-columns: 1fr;
  gap: 32px;
  row-gap: 50px;
  @media (min-width: 800px) {
    grid-template-columns: repeat(2, 1fr) !important;
    max-width: 100%;
  }
  @media (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr) !important;
    max-width: 100%;
  }
  @media (min-width: 1250px) {
    grid-template-columns: repeat(4, 1fr) !important;
    max-width: 100%;
  }
}

//Login-Modal

.picker-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 4;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  right: 0px;
  input {
    display: block;
  }

  .display-none {
    display: none;
  }

  input,
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid hsl(210, 14%, 83%);

    &:focus {
      outline: none;
    }
  }

  input {
    margin-bottom: 16px;
  }

  .modal {
    text-align: center;
    padding: 50px;
    border-radius: 8px;
    background-color: #fff;

    img {
      width: 200px;
      height: auto;
      margin-bottom: 16px;
    }

    p:nth-child(2) {
      font-size: 24px;
      margin-bottom: 8px;
    }

    .btn {
      margin-top: 24px;
      &:hover {
        background-color: transparent;
        border-color: var(--color-theme);
        color: var(--color-theme);
      }
    }

    .btn-login-forgot {
      display: block;
      background-color: transparent;
      border-color: transparent;
    }

    .btn-login-light {
      background-color: transparent;
      border-color: var(--color-theme);
      color: var(--color-theme);

      &:hover {
        color: #fff;
        background-color: var(--color-theme);
        border-color: transparent;
      }
    }
  }
}

.dropzone {
  min-width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  border: 2px dashed var(--color-theme);
  background-color: white;
  transition: 0.3s ease all;
  padding: 0;
  margin: 0;

  label {
    padding: 8px 12px;
    color: #fff;
    background-color: var(--color-theme);
    transition: 0.3s ease all;
    border-radius: 80px;
    font-size: small;
  }

  input {
    display: none;
  }
}

.active-dropzone {
  color: white;
  border-color: white;
  background-color: var(--color-theme);

  label {
    background-color: white;
    color: var(--color-theme);
  }
}

.list-container {
  margin-top: 0px;
}

.list-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding: 5px;
  border-top: 1px solid hsl(210, 14%, 83%);
  border-right: 1px solid hsl(210, 14%, 83%);
  border-left: 1px solid hsl(210, 14%, 83%);
  // cursor: move;
  padding-top: 15px;
  padding-bottom: 15px;
  max-width: 700px;
  p {
    padding: 0;
    margin: 0;
  }

  i {
    margin-right: 20px;
    margin-left: 10px;
    font-size: 20px;
  }
}

.add-workout {
  cursor: pointer;
  border-bottom: 1px solid hsl(210, 14%, 83%);
  color: #000;
}

.workout-edit {
  input,
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;

    &:focus {
      outline: none;
    }
  }

  input {
    margin-bottom: 16px;
  }
}

.list-item-editplan {
  border-collapse: collapse;
  min-width: 200px;
}

.btn-login-forgot {
  // display: block;
  background-color: var(--color-theme);
  border-color: var(--color-theme);
  color: black;
  &:hover {
    color: white;
  }

  .seperate {
    flex: 1;
    order: 2;
    // justify-content: center;
    // align-items: center;
    @media (min-width: 1000px) {
      order: 1;
    }
  }
}

.Mod.open {
  animation: bounce-in 1s ease-in;
}
.Mod.close {
  animation: bounce-out 1s ease-in;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    height: "100%";
  }
  100% {
    opacity: 1;
    height: "0%";
  }
}
@keyframes bounce-out {
  0% {
    // transform: translateY(0%);
    opacity: 1;
    height: "100%";
  }
  100% {
    // transform: translateY(-10%);
    opacity: 0;
    // position: absolute;
  }
}

.lang-menu {
  text-align: right;
  position: relative;
}
.lang-menu .selected-lang {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: var(--color-theme);
}
// .lang-menu .selected-lang:before {
//   content: "";
//   display: inline-block;
//   width: 25px;
//   height: 25px;
//   margin-right: 10px;
//   background-image: url(https://www.countryflags.com/wp-content/uploads/germany-flag-png-large.png);
//   background-size: contain;
//   background-repeat: no-repeat;
// }

.lang-menu ul {
  margin: 0;
  padding: 0;
  display: none;
  background-color: var(--color-light-gray);
  border: 1px solid #f8f8f8;
  position: absolute;
  right: 0px;
  width: 135px;
  border-radius: 5px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
}

.lang-menu ul li {
  padding-top: 10px;
  list-style: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.lang-menu ul li a {
  text-decoration: none;
  width: 135px;
  padding: 5px 10px;
  display: block;
}

.lang-menu ul li:hover {
  background-color: #4c4c4c;
}

.lang-menu ul li {
}

.lang-menu ul li a:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: top;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.de:before {
  background-image: url(https://www.countryflags.com/wp-content/uploads/germany-flag-png-large.png);
}

.en:before {
  background-image: url(https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-large.png);
}

.lang-menu:hover ul {
  display: block;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.css");
