:root {
    --color-theme: #a4ffee;
    --color-light-gray: #000;
    --color-dark-gray: #000;
    --color-gray:#000;
    --color-blue: #007bff;
    --color-light-blue: #ecf5ff;
}

/* 
var(--color-theme)
var(--color-light-gray) 
var(--color-dark-gray)
var(--light-blue) 
var(--color-gray)
var(--color-blue)
var(--color-light-blue)
 */